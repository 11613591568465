<script setup lang="ts">
import AppBar from "~/components/App/AppBar.vue"
import NavDrawer from "~/components/App/NavDrawer.vue"
import { defineAsyncComponent } from "vue"

const Init = defineAsyncComponent(() => import("@/components/Init/Init.vue"))
</script>

<template>
  <AppBar />
  <NavDrawer />
  <v-main class="px-4 my-4">
    <Init />
    <div class="page-content-centred-thin">
      <slot />
    </div>
  </v-main>
</template>

<style scoped lang="scss">
.page-content-centred-thin {
  margin: 0 auto;
  max-width: 768px;
}
</style>
